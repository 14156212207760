import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import VueClipboards from 'vue-clipboard2'
import API from './common/axios';
Vue.prototype.axios = API;

import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/font/font.scss'
import '@/styles/global.css'
import { installVIdentLoading } from './directives/VIdentLoading'
Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(VueClipboards);
installVIdentLoading(Vue)

const app = new Vue({
  router,
  store,
  render: h => h(App)
})

window.app = app

app.$mount('#app')
