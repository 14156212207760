import axios from "axios";
const PRODUCT_BASE_URL = 'https://sms.weiqucloud.com/'
// const PRODUCT_BASE_URL = 'http://192.168.110.21:18081/'
// 测试环境
// const TEST_BASE_URL = 'http://smstest.weiqucloud.com/'
const TEST_BASE_URL = 'http://192.168.110.21:18081/'
import { Loading,Message  } from 'element-ui';
let loadingInstance = null;
// console.log(process.env)
let needRequest = 0
const API = axios.create({
    baseURL: process.env.TEST ? TEST_BASE_URL : PRODUCT_BASE_URL,
    headers: {
        "Access-Control-Allow-Origin": "*",
    },
    timeout: 10000
});

API.interceptors.request.use(config => {
    //请求头设置
    let token = window.localStorage.getItem('token') || ''
    config.headers.token = token
    needRequest++
    loadingInstance = Loading.service({ fullscreen: true, text: '加载中' });
    
    return config
}, err => {
   
})

API.interceptors.response.use(response => {
    needRequest--
    const {code,desc} = response.data 
    if (response.status !== 200) {
        if (needRequest == 0) {
            loadingInstance.close()
        }
        Message({ message: '接口请求失败', type: 'error' })
    } else {
        if (response.data.code == '0000' && needRequest == 0) {
            loadingInstance.close()
        } else if(response.data.code !== '0000' && needRequest == 0) {
            loadingInstance.close();
            Message({
                type: 'error',
                message: desc
            })
        }
    }
    return response

})

export default API 